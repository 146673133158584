import { Controller } from 'stimulus'
import { post } from '../lib/utils'

export default class extends Controller {
  static targets = ['package', 'paymentDetails', 'paymentMethod']
  static values = { totalsUrl: String }

  connect() {
    this.packageTargets[0].dispatchEvent(new Event('click'))
    this.paymentMethodTarget.dispatchEvent(new Event('change'))
  }

  choosePackage() {
    const className = 'package--chosen'
    const radioButton = event.currentTarget.querySelector('input[type=radio]')

    this.packageTargets.forEach(packageItem => packageItem.classList.remove(className))
    event.currentTarget.classList.add(className)

    radioButton.checked = true
    radioButton.dispatchEvent(new Event('change'))
  }

  choosePaymentMethod() {
    const directDebitPaymentMethod = 'direct_debit'
    const paymentMethod = event.currentTarget.value

    this.togglePaymentDetails(paymentMethod == directDebitPaymentMethod)
    this.updateTotals()
  }

  togglePaymentDetails(isVisible) {
    this.paymentDetailsTarget.style.display = isVisible ? 'block' : 'none'
  }

  updateTotals() {
    const paymentMethod = this.paymentMethodTarget.value
    const packageId = document.querySelector('input[name="subscription[package_id]"]:checked').value

    post(
      this.totalsUrlValue,
      {
        package_id: packageId,
        payment_method: paymentMethod
      }
    ).then((response) => {
      Turbo.renderStreamMessage(response.data)
    })
  }
}
