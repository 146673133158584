import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content']

  connect() {
    const editor = ace.edit('code-editor')
    editor.session.setMode('ace/mode/css')

    editor.session.on('change', (delta) => {
      this.contentTarget.value = editor.getValue()
    })
  }
}
