import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    url: String
  }

  click() {
    if (event.target.tagName == 'A') { return }
    if (event.target.tagName == 'BUTTON') { return }
    if (event.target.tagName == 'INPUT') { return }
    if (event.target.tagName == 'TR') { return }
    if (event.target.classList.contains('not-clickable')) { return }
    if (event.target.closest('.not-clickable')) { return }

    window.location = this.urlValue
  }
}
