import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.modal = document.getElementById('modal')
  }

  show() {
    this.modal.classList.add('active')
  }

  hide() {
    this.modal.classList.remove('active')
  }
}
