import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content']

  connect() {
    this.contentTarget.classList.add('hidden')
  }

  close() {
    if (!this.element.contains(event.target)) {
      this.element.classList.remove('open')
    }
  }

  toggle() {
    this.element.classList.toggle('open')
  }
}
