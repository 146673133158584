import { Controller } from 'stimulus'
import TinyMce from '../packs/admin/modules/tinymce'

export default class extends Controller {
  static values = { imageUrl: String }

  connect() {
    this.element.addEventListener('block.loaded', () => { this.initializeTinymceEditor() })
  }

  blurCallback(editor) {
    const form = editor.formElement
    const input = form.querySelector('.content')

    if (input.value != editor.getContent()) {
        input.value = editor.getContent()
        form.requestSubmit()
    }
  }

  initializeTinymceEditor() {
    const tinymce = new TinyMce(this.imageUrlValue, this.blurCallback)
    tinymce.init()
  }
}
