import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.initialValues = new FormData(this.element)
  }

  close() {
    if (this.element.contains(event.target)) { return }
    if (document.getSelection().toString() != '') { return }

    this.element.requestSubmit()
  }

  submit() {
    const initialValue = this.initialValues.get(event.currentTarget.name)

    if (event.currentTarget.value != initialValue) {
      this.element.requestSubmit()
    }
  }
}
