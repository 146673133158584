import TextareaController from './textarea_controller'
import TinyMce from '../packs/admin/modules/tinymce'

export default class extends TextareaController {
  disconnect() {
    tinymce.remove()
  }

  connect() {
    this.initializeTinymceEditor()
  }

  initializeTinymceEditor() {
    tinymce.init({
      branding: false,
      menubar: false,
      selector: '.tinymce',
      skin: false,
      toolbar: 'undo redo | styleselect | bold italic underline |  \
                image link | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | forecolor | fontsizeselect | removeformat code'
    })
  }
}
