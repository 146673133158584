import { Controller } from 'stimulus'
import Pickr from '@simonwep/pickr'

export default class extends Controller {
    connect() {
        const input = this.element

        const picker = Pickr.create({
            el: this.element,
            theme: 'classic',
            default: this.element.value,
            useAsButton: true,
            components: {
                preview: true,
                opacity: true,
                hue: true,
                interaction: {
                    hex: true,
                    input: true,
                    save: false
                }
            }
        })

        picker.on('change', (color) => {
            this.element.value = color.toHEXA().toString()
        })
    }
}
