import { Controller } from 'stimulus'
import TinyMce from '../packs/admin/modules/tinymce'

export default class extends Controller {
  static targets = ['destination', 'template']

  add() {
    event.preventDefault()

    const separator = 'NEW_RECORD_' + new Date().getTime()
    const content = this.templateTarget.innerHTML.split('NEW_RECORD').join(separator)

    this.destinationTarget.insertAdjacentHTML('beforeend', content)
  }

  delete() {
    event.preventDefault()

    event.currentTarget.parentElement.querySelector('.delete').value = '1'
    event.currentTarget.parentElement.style.display = 'none'
  }
}
