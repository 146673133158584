import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['block']
  static values = { isFocused: Boolean }

  connect() {
    const editor = this.element.closest('.editor')
    const event = new Event('block.loaded')

    editor.dispatchEvent(event)

    if (this.isFocusedValue === true) {
      // Simulate a click to let the block focus again.
      this.element.click()
    }

    useClickOutside(this)
  }

  clickOutside(event) {
    this.unfocus()
  }

  focus() {
    // TODO: clean this up.
    // We only want to do stopPropagation() when it's not a turbo related element.

    if (event.target.tagName == 'DIV') {
      event.stopPropagation()
    }

    this.unfocus()
    event.currentTarget.classList.add('block-wrapper--focused')
    this.positionControls()
  }

  hover() {
    event.stopPropagation();

    this.unhover()
    event.currentTarget.classList.add('block-wrapper--hovered')
  }

  positionControls() {
    const blockBottom = event.currentTarget.getBoundingClientRect().bottom
    const controlElements = event.currentTarget.querySelectorAll('.block__controls')

    controlElements.forEach(controlElement => {
      const controlsBottom = controlElement.getBoundingClientRect().bottom

      if (controlsBottom <= blockBottom) {
        controlElement.style.bottom = ((blockBottom - controlsBottom + 10) * -1) + 'px'
      }
    })
  }

  unfocus() {
    const blocks = document.querySelectorAll('.block-wrapper')

    blocks.forEach(block => {
      if (
        event.target.closest('.block-wrapper') != block &&
        event.target.closest('.editor__settings') == null
      ) {
        block.classList.remove('block-wrapper--focused')
      }
    })
  }

  unhover() {
    const hoverClass = 'block-wrapper--hovered'
    document.querySelectorAll('.' + hoverClass).forEach(block => { block.classList.remove(hoverClass) })
  }
}
