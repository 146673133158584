import { Controller } from 'stimulus'

import Sortable from 'sortablejs/Sortable'
import { patch } from '../lib/utils'

export default class extends Controller {
  initialize() {
    const columns = document.querySelectorAll('.column')
    const grids = document.querySelectorAll('.grid')

    columns.forEach(column => {
      new Sortable(
        column,
        {
          animation: 100,
          chosenClass: 'block--chosen',
          fallbackOnBody: true,
          group: `column-#{column.dataset.id}`,
          handle: '.block__move',
          swapThreshold: 1,

          onAdd: (event) => {
            const destination = event.to
            const destinationColumnId = destination.dataset.id
            const url = event.item.dataset.dragUrl

            columns.forEach(column => { column.classList.remove('block--dropped') })

            patch(
              url,
              {
                columnId: destinationColumnId,
                position: event.newDraggableIndex
              }
            )
          },

          onMove: (event) => {
            const destination = event.to

            columns.forEach(column => { column.classList.remove('block--dropped') })
            destination.classList.add('block--dropped')
          },

          onSort: (event) => {
            const url = event.item.dataset.dragUrl

            columns.forEach(column => { column.classList.remove('block--dropped') })

            if (event.from != event.to ) {
              // Don't perform a separate request when moving between lists.
              // onAdd should already handle the position
              return
            }

            patch(
              url,
              { position: event.newDraggableIndex }
            )
          }
        }
      )
    })
  }
}
