import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      const link = event.target.tagName == 'A' ? event.target : null

      if (link == null || link.dataset.action || event.target.dataset.turboFrame) { return }
      event.preventDefault()
    })
  }
}
