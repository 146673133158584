import tinymce from 'tinymce/tinymce'

import 'tinymce/icons/default'
import 'tinymce/themes/silver'

import 'tinymce/plugins/code'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'

export default class TinyMce {
    constructor(uploadUrl, blurCallback = null, selector = '.tinymce', isInline = true) {
        this.blurCallback = blurCallback
        this.isInline = isInline
        this.selector = selector
        this.uploadUrl = uploadUrl
    }

    init() {
        tinymce.init({
            content_css: false,
            file_picker_types: 'image',
            fontsize_formats: '0.4em 0.6em 0.8em 1em 1.2em 1.4em 1.6em 1.8em 2em 2.25em 2.5em 2.75em 3em',
            formats: {
                alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'align-left' },
                alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'align-right' }
            },
            images_upload_url: this.uploadUrl,
            inline: this.isInline,
            menubar: false,
            plugins: this.plugins(),
            relative_urls: false,
            selector: this.selector,
            skin: false,
            toolbar: 'undo redo | styleselect | bold italic underline |  \
                      image link | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | table | forecolor | fontsizeselect | removeformat code',
            init_instance_callback: (editor) => {
                editor.on('blur', () => {
                    if (this.blurCallback) {
                        this.blurCallback(editor)
                    }
                })
            }
        })
    }

    plugins() {
        let plugins = 'code link lists table'

        if (this.uploadUrl) {
            plugins = plugins + ' image imagetools'
        }

        return plugins
    }
}
