import { Controller } from 'stimulus'
import ResizeHandler from '../lib/resize_handler'

export default class extends Controller {
  static targets = ['form', 'resize']

  connect() {
    const resizeHandler = new ResizeHandler(this.element, this.resizeTarget, 250).bind()

    resizeHandler.on('resizeend', event => {
      this.formTarget.requestSubmit()
    })
  }
}
