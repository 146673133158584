import Rails from '@rails/ujs'
import axios from 'axios'

export function patch(url, data = {}) {
    axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()

    return axios.patch(
        url, data
    ).then(function (response) {
        return response
    })
}

export function post(url, data = {}) {
    axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()

    return axios.post(
        url, data
    ).then(function (response) {
        return response
    })
}
