import { Controller } from 'stimulus'
import Sortable from 'sortablejs/Sortable'
import { patch } from '../lib/utils'

export default class extends Controller {
  connect() {
    new Sortable(
      this.element,
      {
        animation: 150,
        handle: '.list__drag-handle',
        onUpdate: function(event) {
          const url = event.item.dataset.sortableListUpdateUrl
          patch(url, { position: event.newIndex })
        }
      }
    )
  }
}
