import interact from 'interactjs'

export default class ResizeHandler {
    constructor(element, handle, minHeight = 0) {
        this.element = element
        this.handle = handle
        this.minHeight = minHeight
    }

    bind() {
        const resizeHandler = interact(this.element).resizable({
            edges: {
                top: false,
                left: false,
                bottom: this.handle,
                right: false
            },

            modifiers: [
                interact.modifiers.restrictSize(
                    { min: { height: this.minHeight } }
                )
            ]
        })

        resizeHandler.on('resizemove', event => {
            event.target.style.height = event.rect.height + 'px'
        })

        resizeHandler.on('resizeend', event => {
            const heightInput = event.target.querySelector('input[name*=height]')

            if (heightInput) {
                heightInput.value = event.rect.height
            }
        })

        return resizeHandler
    }
}
