import { Controller } from 'stimulus'
import Sortable from 'sortablejs/Sortable'

export default class extends Controller {
  static values = { handle: String }

  connect() {
    new Sortable(
      this.element,
      {
        animation: 150,
        handle: this.handleValue,
        onUpdate: (event) => {
          event.srcElement.querySelectorAll('input.position').forEach((input, index) => {
            input.value = index
          })
        }
      }
    )
  }
}
